<template>
    <v-container class="mr-0 px-0">
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">
        </v-skeleton-loader>
        <v-data-table
        v-else
        :items="data"
        :headers="dataHeaders"
        :search = "nameSearchable"
        class="elevation-1 mt-5">
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template v-slot:item.created_at={item}>
                {{ item.created_at.split("T")[0] }}
            </template>
            <template v-slot:item.name="{item}">
                <!-- <a @click="redirectToCompany(data[data.indexOf(item)].companies_id)" class="text-decoration-none">
                {{ item.name }}
                </a> -->
                <router-link v-if="allowedRolesRedirectCompanies.some(role=>$store.getters.getRole.includes(role))" :to="{name:'PageCompaniesDetail',params:{id:data[data.indexOf(item)].companies_id}}">
                    {{ item.name }}
                </router-link>
                <span v-else>
                    {{ item.name }}
                </span>
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" color=" py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <!-- <v-icon large color="black" class="px-2">
                                    fa-user-circle
                                </v-icon> -->
                                <v-toolbar-title class="font-weight-bold text-h4">
                                    Person-in-charge
                                </v-toolbar-title>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="mt-3">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="nameSearchable"
                                label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                        <v-btn color="primary" v-if="currentRole=='operation'" @click="redirectNew">
                            New
                        </v-btn>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    currentRole:null,
    nameSearchable:null,
    data:null,
    allowedRolesRedirectCompanies : ["developer","vision_admin","operation","sales_manager"],

    dataHeaders:[
        {
            text:"#",
            value:"Index",
            align:"start",
        },
        {
            text:'PIC',
            value:'PIC_name',
        },
        {
            text:'Email',
            value:'email',
        },
        {
            text:"Company",
            value:'name',
        },
        {
            text:'Assigned date',
            value:'created_at',
        },
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getCompanyPIC"){
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetchListPIC(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic";
            return tempApi;
        },
        fetch(){
            this.currentRole = this.$store.getters.getRole;
            let ListPICApi = this.fetchListPIC();
            this.$api.fetch(ListPICApi);
        },
        redirectNew(){
            this.$router.push({name:'PagePicCreate'});
        },
        redirectToCompany(companyId){
            // this.$store.commit("updateCompany",companyId);
            let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href,'_blank');
        }
    }
}
</script>